/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const amenities = [
  {
    key: 'round_7pin_adaptor_type1',
    label: '7-pin round connector (big)',
  },
  {
    key: 'round_7pin_adaptor_type2',
    label: '7-pin round connector (small)',
  },
  {
    key: 'rectangular_7pin_adaptor',
    label: '7-pin flat connector',
  },
  {
    key: 'round_13pin_adaptor_euro',
    label: '13-pin round connector (Euro)',
  },
  {
    key: '900mm_cage',
    label: '900mm cage',
  },
  {
    key: '800mm_cage',
    label: '800mm cage',
  },
  {
    key: '600mm_cage',
    label: '600mm cage',
  },
  {
    key: 'mechanical_brakes',
    label: 'Mechanical brakes',
  },
  {
    key: 'electronic_brakes',
    label: 'Electric brakes',
  },
  {
    key: 'portable_electronic_brake_controller',
    label: 'Portable electric brake controller',
  },
  {
    key: 'hydraulic_brakes',
    label: 'Hydraulic brakes',
  },
  {
    key: 'no_brakes',
    label: 'No brakes',
  },
  {
    key: 'ramp',
    label: 'Ramp',
  },
  {
    key: 'no_ramp',
    label: 'No ramp',
  },
  {
    key: 'tipper',
    label: 'Tipper',
  },
  {
    key: 'toolbox',
    label: 'Toolbox',
  },
  {
    key: 'jockey_wheel',
    label: 'Jockey wheel',
  },
  {
    key: 'spare_wheel',
    label: 'Spare wheel',
  },
];

export const categories = [
  { key: 'box', label: 'Box' },
  { key: 'cage', label: 'Cage' },
  { key: 'car', label: 'Car' },
  { key: 'motorbike', label: 'Motorbike' },
  { key: 'enclosed', label: 'Enclosed' },
  { key: 'camping', label: 'Camping' },
  { key: 'boat', label: 'Boat' },
  { key: 'flat-top', label: 'Flat top' },
  { key: 'specialty', label: 'Specialty' },
  { key: 'plant-and-machinery', label: 'Machinery' },
];

export const states = [
  { key: 'ACT', label: 'Australian Capital Territory' },
  { key: 'NSW', label: 'New South Wales' },
  { key: 'NT', label: 'Northern Territory' },
  { key: 'QLD', label: 'Queensland' },
  { key: 'SA', label: 'South Australia' },
  { key: 'TAS', label: 'Tasmania' },
  { key: 'VIC', label: 'Victoria' },
  { key: 'WA', label: 'Western Australia' },
];

export const howDidYouHearAboutUsOptions = [
  { key: 'facebook', label: 'Facebook' },
  { key: 'instagram', label: 'Instagram' },
  { key: 'gumtree', label: 'Gumtree' },
  { key: 'google', label: 'Google' },
  { key: 'other-search-engine', label: 'Other search engine' },
  { key: 'word-of-mouth', label: 'Word of mouth' },
  { key: 'referred', label: 'Referred by a friend' },
  { key: 'trailer-sticker', label: 'Saw a localtrailerhire.com.au sticker on a trailer' },
  { key: 'radio-ad', label: 'Radio advert' },
  { key: 'podcast-ad', label: 'Podcast advert' },
  { key: 'banner-ad', label: 'Banner advert' },
];

export const declineMessageOptions = [
  { key: 'forgotToUpdateAvailability', label: "I forgot to update the trailer's availability" },
  { key: 'somethingCameUp', label: 'Something came up last minute' },
  { key: 'other', label: 'Another reason...' },
];

export const cancelRequestMessageOptions = [
  { key: 'responseRate', label: 'I cannot wait any longer for the booking to be confirmed' },
  { key: 'noLongerRequired', label: "I don't need to hire a trailer any more" },
  { key: 'bookedAnotherTrailer', label: 'I have booked another trailer' },
  { key: 'other', label: 'Another reason...' },
];

export const cancelBookingMessageOptions = [
  { key: 'notAbleToUse', label: "I'm not able to use the trailer at this time" },
  { key: 'noLongerRequired', label: "I don't need to hire a trailer any more" },
  { key: 'bookedAnotherTrailer', label: 'I have booked another trailer' },
  { key: 'forProvider', label: 'Trailer owner requested I cancel' },
  { key: 'other', label: 'Another reason...' },
];
export const cancelNonRefundableBookingMessageOptions = [
  { key: 'notAbleToUse', label: "I'm not able to use the trailer at this time" },
  { key: 'noLongerRequired', label: "I don't need to hire a trailer any more" },
  { key: 'bookedAnotherTrailer', label: 'I have booked another trailer' },
  { key: 'other', label: 'Another reason...' },
];

export const sizeOptions = [
  { key: '6x4', label: "6' x 4' (1.8m x 1.2m)", lengthInMetres: 1.8, widthInMetres: 1.2 },
  { key: '7x4', label: "7' x 4' (2.1m x 1.2m)", lengthInMetres: 2.1, widthInMetres: 1.2 },
  { key: '7x5', label: "7' x 5' (2.1m x 1.5m)", lengthInMetres: 2.1, widthInMetres: 1.5 },
  { key: '8x5', label: "8' x 5' (2.4m x 1.5m)", lengthInMetres: 2.4, widthInMetres: 1.5 },
  { key: '8x6', label: "8' x 6' (2.4m x 1.8m)", lengthInMetres: 2.4, widthInMetres: 1.8 },
  { key: '9x5', label: "9' x 5' (2.7m x 1.5m)", lengthInMetres: 2.7, widthInMetres: 1.5 },
  { key: '10x5', label: "10' x 5' (3.0m x 1.5m)", lengthInMetres: 3.0, widthInMetres: 1.5 },
  { key: '10x6', label: "10' x 6' (3.0m x 1.8m)", lengthInMetres: 3.0, widthInMetres: 1.8 },
  { key: '10x7', label: "10' x 7' (3.0m x 2.1m)", lengthInMetres: 3.0, widthInMetres: 2.1 },
  { key: '12x5', label: "12' x 5' (3.6m x 1.5m)", lengthInMetres: 3.6, widthInMetres: 1.5 },
  { key: '12x6', label: "12' x 6' (3.6m x 1.8m)", lengthInMetres: 3.6, widthInMetres: 1.8 },
  { key: '12x7', label: "12' x 7' (3.6m x 2.1m)", lengthInMetres: 3.6, widthInMetres: 2.1 },
  { key: '14x6', label: "14'x 6' (4.2m x 1.8m)", lengthInMetres: 4.2, widthInMetres: 1.8 },
  { key: "14x6'6", label: "14' x 6'6\" (4.2m x 1.95m)", lengthInMetres: 4.2, widthInMetres: 1.95 },
  { key: '15x6', label: "15' x 6' (4.5m x 1.8m)", lengthInMetres: 4.5, widthInMetres: 1.8 },
  { key: "15x6'6", label: "15' x 6'6\" (4.5m x 1.95m)", lengthInMetres: 4.5, widthInMetres: 1.95 },
  { key: '16x6', label: "16' x 6' (4.8m x 1.8m)", lengthInMetres: 4.8, widthInMetres: 1.8 },
  { key: "16x6'6", label: "16' x 6'6\" (4.8m x 1.95m)", lengthInMetres: 4.8, widthInMetres: 1.95 },
  { key: '20x8', label: "17'+ (5.1m+)" },
  { key: 'custom', label: 'Other custom size' },
];

export const sizeOptionsInterleavedFor2ColumnDisplay = [
  { key: '6x4', label: '6x4' },
  { key: '12x6', label: '12x6' },
  { key: '7x4', label: '7x4' },
  { key: '12x7', label: '12x7' },
  { key: '7x5', label: '7x5' },
  { key: '14x6', label: '14x6' },
  { key: '8x5', label: '8x5' },
  { key: "14x6'6", label: "14x6'6" },
  { key: '8x6', label: '8x6' },
  { key: '15x6', label: '15x6' },
  { key: '9x5', label: '9x5' },
  { key: "15x6'6", label: "15x6'6" },
  { key: '10x5', label: '10x5' },
  { key: '16x6', label: '16x6' },
  { key: '10x6', label: '10x6' },
  { key: "16x6'6", label: "16x6'6" },
  { key: '10x7', label: '10x7' },
  { key: '20x8', label: "17'+" },
  { key: '12x5', label: '12x5' },
];

export const filters = [
  {
    id: 'type',
    label: 'Type',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: categories,
      isTwoColumns: true,
    },
  },
  {
    id: 'size',
    label: 'Size',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_size'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      // searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: sizeOptionsInterleavedFor2ColumnDisplay,
      isTwoColumns: true,
    },
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 200,
      step: 5,
    },
  },
  {
    id: 'amenities',
    label: 'Features',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_amenities'],
    config: {
      // searchMode: 'has_all',
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: amenities,
      isTwoColumns: false,
    },
  },
  // {
  //   id: 'dates-length',
  //   label: 'Dates',
  //   type: 'BookingDateRangeLengthFilter',
  //   group: 'primary',
  //   // Note: BookingDateRangeFilter is fixed filter,
  //   // you can't change "queryParamNames: ['dates'],"
  //   queryParamNames: ['dates', 'minDuration'],
  //   config: {
  //     // A global time zone to use in availability searches. As listings
  //     // can be in various time zones, we must decide what time zone we
  //     // use in search when looking for available listings within a
  //     // certain time interval.
  //     //
  //     // If you have all/most listings in a certain time zone, change this
  //     // config value to that.
  //     //
  //     // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
  //     searchTimeZone: 'Australia/Melbourne',

  //   // Options for the minimum duration of the booking
  //   options: [
  //     { key: '0', label: 'Any length' },
  //     { key: '480', label: '8 hours', shortLabel: '8h' },
  //     { key: '1440', label: '24 hours', shortLabel: '24h' },
  //     { key: '2880', label: '2 days', shortLabel: '2d' },
  //     { key: '4320', label: '3 days', shortLabel: '3d' },
  //     { key: '5760', label: '4 days', shortLabel: '4d' },
  //     { key: '7200', label: '5 days', shortLabel: '5d' },
  //     { key: '8640', label: '6 days', shortLabel: '6d' },
  //     { key: '10080', label: '7 days', shortLabel: '7d' },
  //   ],
  //   },
  // },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: false,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const featureToggles = {
  development: {
    // used in hot loading mode (i.e. yarn run dev)
    chargeGst: true,
    updateResidentialAddress: true,
  },
  local: {
    // used when running server side locally (i.e. yarn run dev-server)
    chargeGst: true,
    updateResidentialAddress: true,
  },
  staging: {
    // lth-dev and lth-staging
    chargeGst: true,
    updateResidentialAddress: true,
  },
  production: {
    chargeGst: true,
    updateResidentialAddress: true,
  },
};
